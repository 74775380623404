import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { changePasswordCredentials } from './changePwd';
import {FormControl, Validators, FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

//Change Password component
export class ChangePasswordComponent implements OnInit {
  credentials : changePasswordCredentials;
  changePasswordForm;


  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.credentials = new changePasswordCredentials(null, null, null);
    
        this.changePasswordForm = new FormGroup({
          oldPasswordFormControl: new FormControl('', [
            Validators.required]),
            newPasswordFormControl: new FormControl('', [
              Validators.required]),
              newPasswordConfirmFormControl: new FormControl('', [
                Validators.required,
                this.PasswordNotMatching()]),
        });
  }

 PasswordNotMatching(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      var confirmPassword = control.value;
      return confirmPassword === this.changePasswordForm && this.changePasswordForm.control['newPasswordFormControl'].value ? null : {'confirmPassword': {value: control.value}};
    };

}
}