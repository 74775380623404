import { Injectable } from '@angular/core';

@Injectable()
export class ViewPermissionsService {
  private screenPermissions;
  constructor() { }

  setScreenPermissions(permissions){
    this.screenPermissions = permissions;
  }

  getScreenPermissions(){
    return this.screenPermissions;
  }

  clearScreenPermissions(){
    this.screenPermissions = null;
  }

}
