import { Component, OnInit,Input,Output,EventEmitter,ViewEncapsulation, OnChanges } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { AppServiceService } from './../../../services/app-service/app-service.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 
@Component({
  selector: 'ae-tpm-policy-check',
  templateUrl: './policy-check.component.html',
  styleUrls: ['./policy-check.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class PolicyCheckComponent implements OnInit {

  @Input() policyTitle:string;
  @Input() policyData:Array<any>; 
  @Input() policyTip:string;
  @Input() agreeString: string;
  @Output() allPolicyChecked = new EventEmitter();

 

  policyRead  = {};
  selectedIndex:number = 0;
  addSessionTimeoutClass :boolean = false;
  constructor(public appServiceService?: AppServiceService) {}

  ngOnInit() { 
    this.appServiceService.sessionTimeoutNotifierSubject.subscribe((data: any)=>{
      this.updateSessionTimeoutClass(data)
    })
  }
  ngOnChanges() {
    this.policyRead = {};
    this.setInitialPolicyData();
  }

  updateSessionTimeoutClass(data){
    if(data && data.hasOwnProperty('isSessionTimeoutPopupVisible')){
      this.addSessionTimeoutClass = data.isSessionTimeoutPopupVisible;
    }
  }
  policyCheck(event,index){
    if(event.checked && this.selectedIndex!=this.policyData.length && this.policyRead[index + 1]===false){
      this.selectedIndex = index + 1;
    }
    if(this.selectedIndex===this.policyData.length && this.policyRead[0]===false){
      this.selectedIndex = 0;
    }
    var element = document.getElementsByClassName("mat-tab-label-active")[0];
    if(event.checked){
      element.classList.add("policy-checked");
    }else{
      element.classList.remove("policy-checked");
    }
  
    for(var itr in this.policyRead){
      if(this.policyRead[itr]===false){
        this.allPolicyChecked.emit(false);
        return;
      }
    }
    this.allPolicyChecked.emit(true);
  }

  setInitialPolicyData(){
    if(this.policyData && this.policyData.length > 0){
      for(var itr =0;itr<this.policyData.length;itr++){
        this.policyRead[itr] = false;
        if(this.policyData[itr]['value'].startsWith('http') || this.policyData[itr]['value'].startsWith('https') || this.policyData[itr]['value'].endsWith('.pdf')){
          this.policyData[itr]['isLink'] = true;
          if((this.policyData[itr]['value'].indexOf('view')!=-1)){
            this.policyData[itr]['value'] = this.policyData[itr]['value'].split('view')[0] + 'preview';
          }
        }
      }
    }
  }

  checkAllPolicies() {
    for (var itr in this.policyRead) {
      this.policyRead[itr] = true;
      var element = document.getElementsByClassName("mat-tab-label")[itr];
      element.classList.add("policy-checked");
    }
    this.allPolicyChecked.emit(true);
  }


  uncheckAllPolicies()
  {
    for(var itr in this.policyRead)
    {
      this.policyRead[itr] = false;
      var element = document.getElementsByClassName("mat-tab-label")[itr];
      element.classList.remove("policy-checked");
    }
    this.allPolicyChecked.emit(false);
    }
  }

