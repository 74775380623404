export function SharedResourceBundleKeys(){
    return [
        "atpm.visit.policy.footer.msg",
        "atpm.visit.policy.retrieve.failMsg",
        "atpm.visit.create.policy.title",
        "atpm.visit.policy.tip",
        "atpm.create.visit.policy.select.msg",
        "atpm.visit.policy.sign",
        "atpm.visit.save.sign",
        "atpm.visit.welcome.note",
        "atpm.visit.signature.note",
        "atpm.websocket.connection.failure"
    ]
}