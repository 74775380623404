
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Restangular } from 'ngx-restangular';
import { ViewPermissionsService } from './service/view-permissions.service';
import { map,catchError } from 'rxjs/operators';
import { TranslationLoaderService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-translate/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { FabricResourceBundleKeys } from 'alntg-fabric-ui-components/src/app/fabric/fabric-resource-bundle.keys';

@Injectable()
export class ViewAccessGuard implements CanActivate {

  constructor(public router: Router, private restangular: Restangular, private viewPermissionsService: ViewPermissionsService,private translationLoader: TranslationLoaderService,  private translate: TranslateService,  private titleService: Title,) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log(next.data["screenName"]);
      var body = {
      name: next.data["screenName"]
      };
      return this.restangular.all('/rest/ui/components').customPOST(body,"",{},{}).pipe(map((result:any)=>{
        var permissions = result;
        return this.checkPermissions(next,permissions);
      },
    error=> { 
      this.router.navigate(['/pageNotFound']);
      return false;
    }))
  }

  checkPermissions(next,permissions){
    if(next.data["screenName"] === "Dashboard,Visits" )
        {
          if(permissions.indexOf("TakeSignature")==-1)
        {
            this.router.navigate(['/pageNotFound']);
            return false;
          }
          else
          {
            this.viewPermissionsService.setScreenPermissions(permissions);
            this.loadFabricModuleResourceKeys();
            return true;
          }
        }
        else if(permissions.length > 0 ){
        this.viewPermissionsService.setScreenPermissions(permissions);
        
      	return true;
      	}
      	else{
      		this.router.navigate(['/pageNotFound']);
      		return false;
      	}
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return observableOf(true);
  }
  getResourceKeys(){
    return FabricResourceBundleKeys.loadResourceBundleKeys().concat(["common.button.cancel","common.ok","atpm.session.timeout.message","atpm.session.timeout.title","atpm.header.title",'atpm.menu.signout'])
   }

  loadFabricModuleResourceKeys(){
    this.translationLoader.loadTranslations(this.getResourceKeys(), "rest/guardian/resourceBundle/values/FabricModule").subscribe(result => {
      this.translate.setTranslation('en', result, true)
      this.titleService.setTitle(result['atpm.header.title']);
    });
  }



}
