import { Component, OnInit } from '@angular/core';
import {AppSettingsService} from './../services/app-settings.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  providers: [AppSettingsService],
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public appSettingsService:AppSettingsService) { }

  ngOnInit() {
    this.appSettingsService.appFavicon();
  }

}
