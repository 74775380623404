import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';
import { AppServiceService } from '../../../services/app-service/app-service.service';
import { ActivatedRoute, Params } from '@angular/router';
import { VisitService } from '../../../visits/visits/services/visit.service';
import { AppConstant } from '../../../app.constant';
import { FabricSnackbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-snackbar/fabric-snackbar.component';
import { MatSnackBar, MatIconRegistry } from '@angular/material';
import Stomp from 'stompjs';
import { Location } from '@angular/common';
import { FabricProgressbarService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-progressbar/service/fabric-progressbar-service.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-take-signature',
  templateUrl: './take-signature.component.html',
  styleUrls: ['./take-signature.component.css']
})
export class TakeSignatureComponent implements OnInit {

  policyData: Array<any>;
  showPolicy: Boolean = true;
  policyTitle = "atpm.visit.create.policy.title";
  policyTip: string = 'atpm.visit.policy.tip';
  agreeString: string = null;
  maintainPolicyData: Array<any>;
  locationName: string;
  maintainIdentityAction;
  allPolicyCheck: boolean;
  signDone: boolean = false;
  opeartionType: string;
  canvasHeight=150;
  canvasWidth=300;
  dotsize=1;

  url: string;
  stompClient: any;
  websocketUrls: Array<any> = [];

  @Input() signData: string;
  connectCounter: number=1;
  uniqueID: any;
  saveSignSuccess : boolean =false;
  showWelcomeScreen : boolean =false;
  logoAsDataURL: string;
  maxReconnectWebsocket : number = 10;
  loadPolicyFromLayout: boolean = false;

  constructor(public appService: AppServiceService, private route: ActivatedRoute,
    private visitorRegistrationService: VisitService, public matSnackbar: MatSnackBar, private location: Location, private fabricProgressbarService: FabricProgressbarService) { 
    }

  ngOnInit() {
    window.onbeforeunload = () => this.ngOnDestroy();
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParamProcessData(params);
      this.location.replaceState('takeSignature');
    })

    this.appService.showToolbarSubject.next(false);
    this.fetchLocalStorageItems();
    if(!this.showWelcomeScreen){
      this.fetchPolicyData();
    }
  }

  fetchLocalStorageItems() {
    if (localStorage.getItem("agreeString")) {
      this.agreeString = localStorage.getItem("agreeString");
      localStorage.removeItem("agreeString");
    }

    if (localStorage.getItem("webSocketURL")) {
      this.url = localStorage.getItem("webSocketURL");
      localStorage.removeItem("webSocketURL");
    }

    if (localStorage.getItem("operationType")) {
      this.opeartionType = localStorage.getItem("operationType");
      localStorage.removeItem("operationType");
    }

    if(localStorage.getItem("logo")){
      this.logoAsDataURL = localStorage.getItem("logo");
      localStorage.removeItem("logo");
    }

    if(localStorage.getItem("loadPolicyFromLayout")){
      this.loadPolicyFromLayout = true;
      localStorage.removeItem("loadPolicyFromLayout");
    }
  }

  fetchPolicyData() {
    if ((this.opeartionType) && (this.opeartionType.toUpperCase() === 'CHECKIN')) {
      this.visitorRegistrationService.getPolicyBasedOnLocation({ "operation": AppConstant.CHECKIN_POLICY_LOCATION_LAYOUT, "locationName": this.locationName }).subscribe(result => {
        this.assignPolicyResult(result);
      })
    }
    else {
      if (!this.loadPolicyFromLayout) {
        this.visitorRegistrationService.getPolicyBasedOnLocation({ "operation": AppConstant.POLICY_LOCATION_LAYOUT, "locationName": this.locationName }).subscribe(result => {
          if (result && result.length > 0) {
            this.policyData = result;
            this.maintainPolicyData = result.map(x => Object.assign({}, x));  
          }
          else {
            this.getPolicyBasedOnLayout();
          }
        }, error => {
          this.showError();
        })
      }
      else {
        this.getPolicyBasedOnLayout()
      }
    }
  }

  getPolicyBasedOnLayout(){
    this.visitorRegistrationService.getPolicyLayout({ "layoutType": AppConstant.POLICY_LAYOUT_FOR_SELF, "identityActionName": this.maintainIdentityAction }).subscribe(result => {
      this.assignPolicyResult(result);
    }, error => {
      this.showError();
    })
  }

  assignPolicyResult(result){
    if (result && result.length > 0) {
      this.policyData = result;
      this.maintainPolicyData = result.map(x => Object.assign({}, x));  
    }
    else {
      this.showError();
    }
  }
  
  showError() {
    this.showPolicy = false;
    this.matSnackbar.openFromComponent(FabricSnackbarComponent, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: ['ae-snackbar'],
      data: 'atpm.visit.policy.retrieve.failMsg',
      duration: 6000
    });
  }

  queryParamProcessData(params) {
    if (params instanceof Object && Object.keys(params).length) {
      if (params["uniqueID"]) {
        this.uniqueID = params["uniqueID"];
      }
      if (params["location"]) {
        this.locationName = params["location"];
      }
      if (params["identityAction"]) {
        this.maintainIdentityAction = params["identityAction"];
      }
    }
  }

  closeWindow() {
    if (!this.allPolicyCheck) {
      this.fabricProgressbarService.hideProgressBar();
      this.matSnackbar.openFromComponent(FabricSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ['ae-snackbar'],
        data: 'atpm.create.visit.policy.select.msg',
        duration: 6000
      });
      return;
    }

    if (!this.signDone) {
      this.fabricProgressbarService.hideProgressBar();
      this.matSnackbar.openFromComponent(FabricSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ['ae-snackbar'],
        data: 'atpm.visit.policy.sign',
        duration: 6000
      });
      return;
    }
    this.showWelcomeScreen=true;
    this.connectWebSocket();
  }

  connectWebSocket() {
    this.stompClient = Stomp.client(this.url);
    this.stompClient.debug = null;
    let that = this;
    this.stompClient.connect({}, function (frame) {
      let msg = {
        uniqueid: that.uniqueID,
        img_data: that.signData
      }
      that.stompClient.send("/topic/signatureDataTopic", {}, JSON.stringify(msg));
      that.saveSignSuccess = true;
      that.connectCounter=1;
      if (that.stompClient) {
        that.stompClient.disconnect(() => {
          console.log("Websocket successfully disconnected");
        })
      }
    }, function (error) {
      that.websocketFailover(that);
    });
  }

  websocketFailover(parentRef) {
    if (parentRef.connectCounter <= parentRef.maxReconnectWebsocket) {
      parentRef.connectWebSocket();
      parentRef.connectCounter++;
    }
    else {
      parentRef.matSnackbar.openFromComponent(FabricSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ['ae-snackbar'],
        data: 'atpm.websocket.connection.failure',
        duration: 5000
      });
    }
  }

  allPolicyChecked(valid) {
    this.allPolicyCheck = valid;
  }

  fetchSignData() {
    if (this.signData != null) {
      this.signDone = true;
    }
    else {
      this.signDone = false;
    }
  }

  ngOnDestroy() {
    if (!this.saveSignSuccess && Stomp) {
      if(this.url){
        try{
          this.stompClient = Stomp.client(this.url);
        }catch(e){
          console.log(e);
        }
        if(this.stompClient){
          let that = this;
          this.stompClient.connect({}, function (frame) {
            let msg = {
              closeWindow: 'Yes',
              uniqueid: that.uniqueID
            }
            that.stompClient.send("/topic/signatureDataTopic", {}, JSON.stringify(msg));
            if (that.stompClient) {
              that.stompClient.disconnect(() => {
                console.log("Websocket successfully disconnected");
              })
            }
          })
        }
      }
    }
  }
}
