
import {throwError as observableThrowError, of as observableOf,  Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import {WebcamImage} from 'ngx-webcam';
import { Resolve } from '@angular/router';
import { FabricProgressbarService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-progressbar/service/fabric-progressbar-service.service';
import { map,catchError } from 'rxjs/operators';
import { AppServiceService } from './app-service/app-service.service';
import { FabricService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-service/fabric.service';
import { HttpClient} from '@angular/common/http';

@Injectable()
export class AppSettingsService implements Resolve<Observable<boolean>>{

  constructor(private httpClient: HttpClient,private restangular : Restangular,private fabricProgressbarService:FabricProgressbarService,private appService: AppServiceService,private fabricService : FabricService) { }
  
  private appData={};
  private userLoggedIn = false;
  public appDataCatcher = new Subject();
  public userLoggedInTrigger = new BehaviorSubject(this.userLoggedIn);
  public appSettings;
  public websocketUrls;
  public logo;
  public dateFormat;
  public dashboardGridRefreshRate;
  
  throwEvent(result){
    this.appData = result;
    this.appDataCatcher.next(this.appData);
  }

  userLoggedInOrOut(isLoggedIn){
    this.userLoggedIn = isLoggedIn;
    if(!this.userLoggedIn){
      this.appData = {};  //Empty App Data when user is logged out
    }
    this.userLoggedInTrigger.next(this.userLoggedIn);
  }

  appFavicon(){
    
    this.getAppFavicon().subscribe(result =>{
      if(result['favicon']){
        var mimeTypeSubset = result['favicon'].split(';');
        var mimeType = mimeTypeSubset[0].split(':');    
        var appFavicon = new WebcamImage(result['favicon'], mimeType[1],null);
        document.getElementById('appFavicon').setAttribute('href', appFavicon.imageAsDataUrl);
      }else{
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
      }
    },error=>{
      document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
    });
  }

  resolve(){
    if(Object.keys(this.appData).length==0){
      this.getAppSettings().subscribe(result=>{
        this.appSettings = result;
        this.appService.setGuardianUrl(result.guardianUrl);
        if (result && result['websocketUrls']) {
          this.websocketUrls = result.websocketUrls;
        }
        if(result && result['logo']){
          this.logo = result.logo;
        }
        if(result && result['dateFormat']){
          this.dateFormat = result['dateFormat'];
          this.fabricService.setDateFormat(this.dateFormat);
        }

        if(result && result['dashboardGridRefreshRate']){
          this.dashboardGridRefreshRate = result['dashboardGridRefreshRate'];
        }

        if(result && result.hasOwnProperty('hideWarningMessage')){
          this.fabricService.setHideWarningMessage(result['hideWarningMessage']);
        }
        this.throwEvent(result);
        this.fabricProgressbarService.hideProgressBar();
      },error=>{
        this.fabricProgressbarService.hideProgressBar();
      });
    }
    return observableOf(true);
  }

  getAppSettings() : Observable <any> {
    return this.restangular.one('rest/application/appSettings').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  getDirectLinks() : Observable <any> {
    return this.restangular.one('rest/ui/directLinks').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  getAppFavicon() : Observable <any> {
    return this.httpClient.get('rest/guardian/appFavicon' , {}).pipe(map((res)=>{return res}),catchError(this.handleError),);    
  }

  resetUserSession() : Observable <any> {          
    return this.restangular.one('rest/ui/session').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  logout() : Observable <any> {
    return this.restangular.one('rest/login/logout').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }

}
