import {Location} from '@angular/common'
import { AppConstant } from './app.constant';
import * as moment from 'moment-timezone';

export class Utils {

    constructor(private location : Location){

    }
    static getDocumentsRoot(){
        if(location.protocol==="file:"){
            //if we are running it from local enviorment
            var str=location.href;
            return str.substring(0,(str.indexOf('src')+3));
        }
        else{
            var context = location.pathname;
            var idx = -1;
            if ((idx = context.indexOf("/", 1)) != -1) {
                context = context.substring(0, idx);
                return location.protocol + "//" + location.host + context;
            }
            return location.protocol + "//" + location.host;
        }
    }
    static getHelpRoot(){
        return location.protocol + "//" + location.host;
    }

    static getResolvedDataGridValue(mappingValue, row, column) {
        if (mappingValue && mappingValue != '') {
          return mappingValue;
        }
        var defaultVal = column.defaultValue;
    
        if (defaultVal) {
          let uniqueVal = '$~~' + new Date().getTime() + '~~';
          defaultVal = defaultVal.replace(/[$]/g, uniqueVal);
          while (defaultVal.indexOf(uniqueVal) != -1) {
            var index = defaultVal.indexOf(uniqueVal) + uniqueVal.length - 1;
            var subStr = defaultVal.substring(index + 2, defaultVal.indexOf('>', index));
            if (row.hasOwnProperty(subStr) && row[subStr] != '') {
              defaultVal = defaultVal.replace(uniqueVal + '<' + subStr + '>', function () {
                              return row[subStr];
                            });
            } else {
              defaultVal = defaultVal.replace(uniqueVal + '<' + subStr + '>', '');
            }
          }
          defaultVal = defaultVal.replace(/null/g, ""); //To replace null
          defaultVal = defaultVal.replace(/~/g, ""); //To replace unused ~
          defaultVal = defaultVal.replace(/(\({1}\s*\){1})/g, ""); // To replace empty '()'
          defaultVal = defaultVal.replace(/(<br>\s*$)/g, ""); //To remove useless break if there is no words after
          defaultVal = defaultVal.replace(/(-\s*$)/g, ""); //To remove useless - if there is no words after -
          return defaultVal;
        }
        return '';
      }

      static checkVisitStatusForCheckin(status){
        let allowedStatus = [
            AppConstant.VISIT_STATUS_REGISTERED,
            AppConstant.VISIT_STATUS_PEND_REGISTRATION,
            AppConstant.VISITS_STATUS_CHECKED_OUT,
            AppConstant.VISIT_STATUS_CHECK_IN_FAILURE,
            AppConstant.VISITS_STATUS_CHECKOUT_PROGRESS
        ]
        if(allowedStatus.indexOf(status)==-1){
          return false;
        }
        return true;
      }
        
      static checkVisitStatusForIssueBadge(status){
        let allowedStatus = [
            AppConstant.VISITS_STATUS_CHECKED_IN,
            AppConstant.VISITS_STATUS_CHECKIN_PROGRESS,
            AppConstant.VISIT_STATUS_ISSUE_BADGE_FAILURE
        ]
        if(allowedStatus.indexOf(status)==-1){
          return false;
        }
        return true;
      }
        
      static checkVisitStatusForCheckout(status){
        let allowedStatus = [
            AppConstant.VISITS_STATUS_CHECKED_IN, 
            AppConstant.VISITS_STATUS_ISSUE_BADGE_SUCCESS, 
            AppConstant.VISIT_STATUS_ISSUE_BADGE_PROGRESS, 
            AppConstant.VISIT_STATUS_ISSUE_BADGE_FAILURE, 
            AppConstant.VISIT_STATUS_CHECK_OUT_FAILURE
        ]
        if(allowedStatus.indexOf(status)==-1){
          return false;
        }
        return true;
      }

      static checkVisitStatusForMassPrinting(status: string): boolean{
        let allowedStatus = [
          AppConstant.VISIT_STATUS_REGISTERED, 
          AppConstant.VISITS_STATUS_CHECKIN_PROGRESS, 
          AppConstant.VISITS_STATUS_CHECKED_IN, 
          AppConstant.VISIT_STATUS_ISSUE_BADGE_PROGRESS, 
          AppConstant.VISITS_STATUS_ISSUE_BADGE_SUCCESS,
          AppConstant.VISIT_STATUS_CHECK_IN_FAILURE, 
          AppConstant.VISIT_STATUS_ISSUE_BADGE_FAILURE
        ]
        if(allowedStatus.indexOf(status)==-1){
          return false;
        }
        return true;
      }

      static getButtonEnable(data,mappingField,futureTimeBuffer){
        if(mappingField == 'checkin'){
            if(data.VisitStatus==AppConstant.VISIT_STATUS_PEND_REGISTRATION || data.VisitStatus==AppConstant.VISITS_STATUS_CHECKOUT_PROGRESS){
                return true;
            }
        } else if(mappingField == 'issuebadge'){
            if(data.VisitStatus==AppConstant.VISITS_STATUS_ISSUE_BADGE_SUCCESS || data.VisitStatus==AppConstant.VISIT_STATUS_FAILURE || data.VisitStatus==AppConstant.VISITS_STATUS_CHECKIN_PROGRESS){
                return true;
            }
        }
        if(mappingField == 'checkout'){
          return (new Date().valueOf() < (moment(data.visitStartDate).toDate().valueOf() - (futureTimeBuffer * 60 * 1000)));
        }

        let visitEndDate = moment(data.visitEndDate).toDate();
        let visitEndCurrDateWithTime = new Date(new Date().setHours(visitEndDate.getHours(),visitEndDate.getMinutes(),visitEndDate.getSeconds(),visitEndDate.getMilliseconds()));
        return (new Date().valueOf() < (moment(data.visitStartDate).toDate().valueOf() - (futureTimeBuffer * 60 * 1000)) ||  new Date().valueOf() > moment(data.visitEndDate).toDate().valueOf()
                  || (data.recurrenceId && visitEndCurrDateWithTime.valueOf() < new Date().valueOf()))
      }
}
