import { Component, OnInit, Inject, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FabricFormPhotoUploadTemplateComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-comp-form/fabric-dynamic-form/components/fabric-form-templates/fabric-form-upload-capture-template/component/upload-capture-popup-template/upload-capture-popup-template.component';
import { MatDialog } from '@angular/material';
import { WebcamImage } from 'ngx-webcam';
import { FaceRecognitionService } from './service/face-recognition.service';
import { MatSnackBar } from '@angular/material';
import { FabricSnackbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-snackbar/fabric-snackbar.component';
import { FabricProgressbarService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-progressbar/service/fabric-progressbar-service.service';

declare var Webcam;  //Webcam is the Object that is present in webcam.js that can be accessed in ts file

@Component({
  selector: 'ae-face-recognition',
  templateUrl: './face-recognition.component.html',
  styleUrls: ['./face-recognition.component.css']
})
export class FaceRecognitionComponent implements OnInit {
  @Input() identityActionName;
  @Input() locationName;
  @Input() layoutMappingTypeName
  @Input() getLayoutData;
  @Input() disabled;
  @Input() buttonType;

  @Output() emitEvent = new EventEmitter();
  @Output() emitImageObj = new EventEmitter();

  dialogRef;
  public webcamImage: WebcamImage = null;

  faceRecognitionService: FaceRecognitionService;


  constructor(public dialog: MatDialog, faceRecognitionService: FaceRecognitionService, public matSnackbar: MatSnackBar, public fabricProgressbarService: FabricProgressbarService) {
    this.faceRecognitionService = faceRecognitionService;
  }
  ngOnInit() { }

  capturePhoto() {
    if (!this.locationName) {
      this.matSnackbar.openFromComponent(FabricSnackbarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        panelClass: ['ae-snackbar'],
        data: 'atpm.visit.face.recognition.locationNotSelected.error.msg',
        duration: 6000
      });
      return;
    }
    var file;
    this.dialogRef = this.dialog.open(FabricFormPhotoUploadTemplateComponent, {
      data: { restUrl: file },
      disableClose: true
    });
    this.dialogRef.afterClosed().subscribe(result => {
      this.handleDialogCloseEvent(result);
    });
  }

  handleDialogCloseEvent(result: any) {
    if (result && result.value) {
      this.emitImageObj.emit(result.value);
      this.fabricProgressbarService.showProgressBar();
      let formData = this.getFileObjectFromBase64(result.value.imageAsDataUrl.split(',')[1], result.value._mimeType);
      if (this.getLayoutData) {
        this.faceRecognitionService.walkinFaceRecognize(formData, {
          identityActionName: this.identityActionName,
          layoutMappingTypeName: this.layoutMappingTypeName,
          locationName: this.locationName
        }).subscribe(data => {
          if (data) {
            this.emitEvent.emit(data);
          }
        },error=>{
          this.fabricProgressbarService.hideProgressBar();
          this.matSnackbar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:error, duration: 5000});              
        });
      }else{
        this.faceRecognitionService.faceRecognize(formData, {
          locationName: this.locationName
        }).subscribe(data => {
          if (data) {
            this.emitEvent.emit(data);
          }
        },error=>{
          this.fabricProgressbarService.hideProgressBar();
          this.matSnackbar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:error, duration: 5000});              
        });
      }
    }
  }

  getFileObjectFromBase64(imageAsBase64, mimeType) {
    let fd = new FormData();
    let byteString = window.atob(imageAsBase64);
    let arrayBuffer = new ArrayBuffer(byteString.length);
    let int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([int8Array], { type: mimeType });
    fd.append("file", blob)
    return fd;
  }

}

