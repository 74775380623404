import { Component, OnInit,Input,OnChanges,Output,EventEmitter,ViewEncapsulation } from '@angular/core';
import { IdentityCorrectionService } from './service/identity-correction.service';
import { FabricFormEventService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-comp-form/fabric-dynamic-form/services/fabric-form-event.service';
import { FabricProgressbarService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-progressbar/service/fabric-progressbar-service.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FabricSnackbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-snackbar/fabric-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { AppConstant } from '../../../app.constant';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ae-identity-correction',
  templateUrl: './identity-correction.component.html',
  styleUrls: ['./identity-correction.component.css'],
  encapsulation : ViewEncapsulation.None,
  providers:[IdentityCorrectionService,DatePipe]
})
export class IdentityCorrectionComponent implements OnInit,OnChanges {

  @Input() userInfo;
  @Input() locationName;
  @Input() correctionModel;
  @Input() triggerChange;
  @Output() correctionModelChange:EventEmitter<any> = new EventEmitter();
  @Input() isDashboard : boolean = true;

  fields;
  userIdField = [];
  resourceKeys;
  userIdModel;
  isFormValid: boolean = true;
  formTouchFields: boolean = false;
  identityActionName;
  puUserId = '';
  puDetails;
  id;
  initModel = {};
  constructor(public identityCorrectionService:IdentityCorrectionService,
    private fabricFormEventService:FabricFormEventService,
    private fabricProgressbarService:FabricProgressbarService,
    private matSnackBar:MatSnackBar,
    public dialog: MatDialog,
    private translateService:TranslateService,
    private datePipe : DatePipe) {

   }

  ngOnInit() {
    this.translateService.get(['atpm.visit.correction.merge.msg','atpm.visit.merge.with']).subscribe(result=>{
      this.resourceKeys = result;
    })
    this.fabricFormEventService.fabricFormEventCatcher.subscribe(result=>{
      if(Object.keys(result).length>0){
        let key = result['fieldName'];
        if(key==AppConstant.TPM_SEARCHABLE_USER_ID){
          this.visitorUserIdSelected(result);    
        }
      }
    });
  }
  visitorUserIdSelected(event){
    if(event['fieldName']===AppConstant.TPM_SEARCHABLE_USER_ID){
      let params = {};
      this.puUserId = event['value'][AppConstant.USER_ID];
      this.puDetails = event['value'];
      params[AppConstant.USER_ID] = this.puUserId;
      params[AppConstant.IDENTITY_ACTIONS_NAME] = this.identityActionName;
      params[AppConstant.LAYOUT_MAPPING_TYPE_NAME] = AppConstant.IDENTITY_CORRECTION_LAYOUT;
      this.identityCorrectionService.loadDataPU(params).subscribe(result=>{
        this.populatePuData(result);
        this.fabricProgressbarService.hideProgressBar();
      },error=>{
        this.matSnackBar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:error, duration: 6000});       
        this.fabricProgressbarService.hideProgressBar();
      })
  }}
  ngOnChanges(change){
    if(change.userInfo && change.userInfo.currentValue!=undefined){
      this.id =change.userInfo.currentValue.id;
      this.loadInitialData(this.id);
    }
    if(change.triggerChange && change.triggerChange.currentValue!=undefined){
      this.loadInitialData(this.id);
    }
  }

  loadInitialData(id){
    this.fabricProgressbarService.showProgressBar();
    this.puUserId = '';
    this.fields = [];
    this.userIdField = [];
    this.correctionModel = undefined;
    this.userIdModel = undefined;
    this.puDetails = undefined;
    this.identityCorrectionService.loadData(id).subscribe(result=>{
      this.formFieldData(result);
      this.fabricProgressbarService.hideProgressBar();
    },error=>{
      this.matSnackBar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:error, duration: 6000});
      this.fabricProgressbarService.hideProgressBar();
    });
  }

  checkForDataChange(){
    let keys = Object.keys(this.initModel);
    let isChanged : boolean = false;
    for(let itr=0;itr<keys.length;itr++){
      if(this.initModel[keys[itr]] != this.correctionModel[keys[itr]]){
        isChanged = true;
        break;
      }
    }
    return isChanged;
  }

  saveIdentityCorrection(){
  if(!this.checkForDataChange()){
    this.matSnackBar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:'atpm.identity.corr.no.value.change', duration: 6000});
    return;
  }
  this.formTouchFields = true;
  if(!this.isFormValid) {
    this.matSnackBar.openFromComponent(FabricSnackbarComponent, {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: ['ae-snackbar'],
      data: 'atpm.create.visit.allfields.msg',
      duration: 6000
    });
    return;
  }
  this.fabricProgressbarService.showProgressBar();
    this.correctionModel['id'] = this.userInfo['id'];
    this.correctionModel[AppConstant.USER_ID] = this.userInfo[AppConstant.USER_ID];
    this.correctionModel[AppConstant.LOCATION_NAME] = this.locationName;
    if(this.isDashboard){
      this.correctionModel['isUpcoming'] = false;
    }else{
      if(this.userInfo){
        if(new Date(this.userInfo.VisitStartDate).valueOf() <= new Date().valueOf()){
          this.correctionModel['isUpcoming'] = false;
        }else{
          this.correctionModel['isUpcoming'] = true;
        }
      }
    }
    if(this.correctionModel.hasOwnProperty('DateOfBirth')){
      this.correctionModel['DateOfBirth'] = this.datePipe.transform(this.correctionModel['DateOfBirth'],'MMM d, y');
    }
    this.identityCorrectionService.saveIdentityCorrection(this.correctionModel,this.puUserId).subscribe(result=>{
      this.correctionModel = undefined;
      this.initModel = {};
      this.correctionModelChange.emit({action : AppConstant.IDENTITY_CORRECTION_NAME,result:result,oldUserId:this.userInfo[AppConstant.USER_ID],puUserId:this.puUserId,keys:this.resourceKeys,puDetails:this.puDetails});
      this.fabricProgressbarService.hideProgressBar();
    },error=>{
      this.matSnackBar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data:error, duration: 6000});              
      this.fabricProgressbarService.hideProgressBar();
    });
  }
  formFieldData(result){
    this.identityActionName = result['identityAction'];
    result.layoutFields[0]['hideGroup'] = true;
    for(let itr = 0;itr < result.layoutFields.length;itr++){
      if(result.layoutFields[itr].name === AppConstant.TPM_SEARCHABLE_USER_ID){
        this.userIdField.push(result.layoutFields[itr]);
        this.userIdField[0]['hideGroup'] = true;
        result.layoutFields.splice(itr,1);
        break;
      }
    }
    let initModel = {};
    for(let itr = 0;itr < result.layoutFields.length;itr++){
      initModel[result.layoutFields[itr].name] = result.layoutFields[itr].value;
    }
    this.initModel = Object.assign({},initModel);
    this.fields = result.layoutFields.map(x => Object.assign({}, x));
  }

  populatePuData(result){
    if(result && result.length > 0){
      this.fields = [];
      this.userIdField = [];
      this.correctionModel = undefined;
      this.userIdModel = undefined;
      result[0]['hideGroup'] = true;
      for(let itr = 0 ; result.length > 0;itr++){
        if(result[itr].name === AppConstant.TPM_SEARCHABLE_USER_ID){
          result[itr].value = this.puUserId;
          this.userIdField.push(result[itr]);
          this.userIdField[0]['hideGroup'] = true;
          result.splice(itr,1);
          break;
        }
      }
      this.fields = result.map(x => Object.assign({}, x));
    }  
  }
}