export class AppConstant{
    public static LOC_LAYOUT_ATTR_CHECKINLAYOUT = "CheckInLayout";
    public static LOC_LAYOUT_ATTR_CHECKOUTLAYOUT = "CheckOutLayout";
    public static LOC_LAYOUT_ATTR_ISSUEBADGELAYOUT = "IssueBadgeLayout";
    public static LOC_LAYOUT_ATTR_NEWVISITORLAYOUT = "NewVisitorLayout";
    public static LOC_LAYOUT_ATTR_GETDASHBOARDGRIDCOLUMNS = "DashboardGridColumnsLayout";
    public static LOC_LAYOUT_ATTR_GETMODIFYVISITGRIDCOLUMNS ="ModifyVisitGridColumnsLayout";
    public static LOC_LAYOUT_ATTR_GETLISTVISITGRIDCOLUMNS ="ListVisitGridColumnsLayout";
    public static LAYOUT_MAPPING_TYPE_NAME_CREATE_VISITOR_LAYOUT ="Create Visitor Layout";
    public static LOCATION_NAME ="locationName";
    public static OPERATION_NAME ="operation";
    public static DIRECT_CHECKOUT = "DirectCheckout";
    public static IDENTITY_ACTIONS_NAME ="identityActionName";
    public static IDENTITY_ACTION ="identityAction";
    public static UPLOAD_FILE_SKIP_ROWS ="uploadFileSkipRows";
    public static LAYOUT_MAPPING_TYPE_NAME ="layoutType";
    public static FROM_DATE = "fromDate";
    public static TO_DATE = "toDate";
    public static REST_SETUP_URL= "URL";
    public static REST_SETUP_BASIC_AUTH_PASSWORD= "REST_SETUP_BASIC_AUTH_PASSWORD";
    public static REST_SETUP_BASIC_AUTH_USERNAME= "REST_SETUP_BASIC_AUTH_USERNAME";
    public static REST_SETUP_KEYSTORE_PATH= "KEYSTORE_PATH";
    public static REST_SETUP_KEYSTORE_SECRET= "KEYSTORE_SECRET";
    public static REST_SETUP_TRUSTSTORE_PATH= "TRUSTSTORE_PATH";
    public static REST_SETUP_TRUSTSTORE_SECRET= "TRUSTSTORE_SECRET";
    public static REST_SETUP_REDIRECT_URL= "Redirect_URL";    
    public static REST_SETUP_IS_PASSWORD= "IS_PASSWORD";    
    public static IS_PROVISIONED_USER ="IsProvisionedUser";
    public static PROVISIONED_USER_GROUP_VISIT_HEADER_PARAMETER ="PROVISIONED_USER_GROUP_VISIT_HEADER_PARAMETER";
    public static PROVISIONED_USER_GROUP_VISIT_DOMAIN ="PROVISIONED_USER_GROUP_VISIT_DOMAIN";
    public static DATE_FORMAT = "MM/dd/yyyy";
    public static CREATE_VISIT="CreateVisit";
    public static REST_SETUP_SMS_ACCOUNTSID= "REST_SETUP_SMS_ACCOUNTSID";
    public static REST_SETUP_SMS_AUTHTOKEN= "REST_SETUP_SMS_AUTHTOKEN";
    public static DATA_CENTER="DataCenter";
        
    public static VISIT_STATUS_PEND_REGISTRATION= "50PEND"; 
    public static VISIT_STATUS_REGISTERED= "60REG"; 
    public static VISITS_STATUS_CHECKIN_PROGRESS= "65CINP"; 
    public static VISITS_STATUS_CHECKED_IN= "70CIN"; 
    public static VISITS_STATUS_TEMP_CHECKED_OUT= "70COUT";
    public static VISITS_STATUS_CHECKOUT_PROGRESS= "70COUP"; 
    public static VISITS_STATUS_CHECKED_OUT= "80COU"; 
    public static VISITS_STATUS_CHECKED_OUT_FINAL= "85COUFI"; 
    public static VISITS_STATUS_CHECKED_OUT_AUTO= "80COUA"; 
    public static VISITS_STATUS_NO_SHOW= "40NOSH"; 
    public static VISIT_STATUS_CANCEL= "40CANC";
    public static VISIT_STATUS_FAILURE= "40FAIL";
    public static VISIT_STATUS_ISSUE_BADGE_PROGRESS = "70ISSBP";
    public static VISITS_STATUS_ISSUE_BADGE_SUCCESS= "75ISSB";
    public static VISIT_STATUS_CHECK_IN_FAILURE= "68CINF";
	public static VISIT_STATUS_ISSUE_BADGE_FAILURE= "70ISSBF";
	public static VISIT_STATUS_CHECK_OUT_FAILURE= "70COUF";
    public static VISIT_STATUS_FINAL_CHECK_OUT_FAILURE= "80FCOUF";
    public static VISITS_STATUS_REJECTED= "45REJ";
    
    public static NEW_VISITOR_LAYOUT = "Create Visitor Layout";
    public static NEW_VISITOR_CHANGEABLE_LAYOUT = "Create Visitor Changeable Layout"
    public static CHANGEABLE = "changeable";
    public static VISITOR_SEARCHABLE = "visitsearchable";
    public static POLICY_LAYOUT_FOR_SELF = "policylayoutforself";
    public static USER_ID = "UserId";
    public static ID = "id";
    public static LOCATION_NAME_CUT_OVER = "VisitorLocationName";
    public static SHOW_POLICY = "showPolicy";
    public static TPM_SEARCHABLE_USER_ID = "TPMSearchableUserId";
    public static SHOW_RECURRENCE = "ShowRecurrence";
    public static REQUEST_FOR_SELF = "self";
    public static REQUEST_FOR_OTHERS = "others";
    public static MASS_UPLOAD_PU_NPU = "massUploadPU_NPU";
    public static CUT_OVER = "cutOver";
	public static PURPOSE_OF_VISIT = "PurposeOfVisitPU";
	public static PASSWORD_PHRASE_ENC = "PASSWORD_PHRASE_NEW";
	public static SSO_USER_NAME = "SSO_USER_NAME";
    public static SSO_USER_PASSWORD = "SSO_USER_PASSWORD";
    public static WEBSOCKET_URL = "WEBSOCKET_URL";
    public static VIEW_SUBMITTED_ID = "VIEW_SUBMITTED_ID";
    public static DEFAULT_WEBSOCKET_URL = "ws://localhost:61614";
    public static UPLOAD_IMAGE = "UPLOAD_IMAGE";
    public static UPLOAD_KIOSK_IMAGE = "UPLOAD_KIOSK_IMAGE";
    public static UPLOAD_FAVICON_IMAGE = "UPLOAD_FAVICON_IMAGE";
		
    //For DashBoard Counts
    public static DASHBOARD_CHECKIN = "Checkin";
    public static DASHBOARD_CHECKOUT = "Checkout";
    public static DASHBOARD_REGISTRATION = "Registration";
    public static DASHBOARD_VISITOR_CREATION = "VisitCreation";
    public static VAR_PENDINGCHECKIN= "Pending Checkin";
	public static VAR_PENDINGCHECKIN_DECREMENT= "Pending Checkin Decrement";
	public static VAR_PENDINGCHECKOUT= "Pending Checkout";
	public static VAR_PENDINGAPPROVAL= "Pending Approval";

    //custom Columns Action
    public static CUSTOM_COLUMN_CHECKIN="checkin";
    public static CUSTOM_COLUMN_ISSUE_BADGE="issuebadge";
    public static CUSTOM_COLUMN_CHECKOUT="checkout";
    public static CUSTOM_COLUMN_PRINT_BADGE="printBadge";
    public static SHOW_CURRENT_ACCESS="currentUserAccess";
    
    public static VISITSTATUS_ACTION_MODIFY_VISITS="ModifyVisits";
    public static DATA_MAP_NAME="dataMap";
    public static CUSTOM_COLUMN_RESUBMIT="resubmit";

    //Websocket Status for refresh Grid
    public static REFRESH_GRID_DATA = "refreshGrid";
    public static REFRESH_GRID_DATA_FOR_IDENTITY_CORRECTION = "refreshGridForIdentityCorrection";
    public static REFRESH_DASHBOARD_COUNT = "refreshDashboardCount";
    public static STD_ATTR_STARTDATE= "VisitStartDate";
    public static STD_ATTR_ENDDATE= "VisitEndDate";

    //visits table fixed attributes
    public static STD_ATTR_VISITID = "VisitId";
    public static STD_ATTR_GROUPID = "GroupId";
    public static STD_ATTR_USERID = "UserId";
    public static STD_ATTR_VISITSTATUS= "VisitStatus";
    public static STD_ATTR_VISIT_CURR_STATUS = "VisitCurrentStatus";
    public static STD_ATTR_GUARDIAN_MEESAGE = "guardianMessage";
    public static STD_ATTR_UPDATED_DATE = "updatedDate";
    public static ATTR_PARTITION_ID = "PartitionId";
    public static STD_ATTR_DATA_CENTER = "DataCenter";

    public static STD_ATTR_CHECKOUT_DATE = "LastCheckOutDate";
    
    public static REG_ID = "regId";
    public static LOAD_POLICY = "loadPolicy";
    public static POLICY_LOCATION_LAYOUT = "PolicyLocationLayout";
    public static CHECKIN_POLICY_LOCATION_LAYOUT = "CheckinPolicyLocationLayout"; 
    public static GOOGLE_SHEET_TEMPLATE_LAYOUT = "GoogleSheetTemplateLayout";
    public static IDENTITY_CORRECTION_LAYOUT = "identityCorrectionLayout";   
    public static CUT_OVER_LAYOUT = "cutOverLayout"; 
    public static CHECK_USER_IN_WATCH_LIST = "checkUserInWatchList";
    public static CHECK_ALLOWED_WATCHLIST_USER_REQUEST_CREATION = "watchlistReqAllowed";

    //Kiosk Constants
    public static KIOSK_SETUP_LAYOUT_TYPE = "kioskSetupLayout";
    
    public static CHECK_RETURN_VISITOR = "checkReturnVisitor";
    public static VISITOR_ATTRIBUTE_NOT_IN_DUPLICATE_LIST_ATTRIBUTES = "VISITOR_ATTRIBUTE_NOT_IN_DUPLICATE_LIST_ATTRIBUTES";
    public static AUTO_BADGE_ISSUANCE = "AUTO_BADGE_ISSUANCE";
    public static AUTO_REFRESH_DASHBOARD = "AUTO_REFRESH_DASHBOARD";
    public static MODIFY_VISIT_DATE_RANGE_MONTHS = "MODIFY_VISIT_DATE_RANGE_MONTHS";
    public static AUTO_BADGE_GENERATION = "autoBadgeGeneration";
    public static REFRESH_GRID_DATA_FINALCHECKOUT = "REFRESH_GRID_DATA_FINALCHECKOUT";
    public static SPECIAL_KEYS_TO_REMOVE_FROMEND_DASHBOARD_SCANNING = "SPECIAL_KEYS_TO_REMOVE_FROMEND_DASHBOARD_SCANNING";

    //Error Code
    public static ERR_PRINT_BADGE_CODE = 841;
    public static IDENTITY_CORRECTION_NAME = "identityCorrection"

    public static REMOVE_ALL_BADGES="removeBadge";
    //Watchlist
    public static WATCHLIST_LAYOUT_TYPE = "watchlistLayout";
    public static FORM_NAME_PROPERTY = "name";
    public static FORM_VALUE_PROPERTY = "value";
    public static FORM_TYPE_PROPERTY = "type";
    public static VALIDATION_ERROR_PATTERN ="PATTERN_ERROR";
    public static VALIDATION_ERROR_MINMAX ="MINMAX_ERROR";
    public static VALIDATION_ERROR_COMBO ="COMBO_ERROR"; 
    public static VALIDATION_ERROR_REQUIRED ="REQUIRED_ERROR";
    public static FILE_SIZE_IN_KB ="fileSizeInKB";
    public static WATCHLIST_GUID ="watchlist-photo-";

    public static ACTION_COPY_VISIT ="copyVisit";
    public static TPM_TOKEN ="tpm_token";

    public static TEMP_PHOTO = "temp-photo-";

    public static BADGE_STATUS_FAILURE= "atpm.tempbadge.status.failed"; 

    public static SHORTCUT_KEY_CHECKIN = "SHORTCUT_KEY_CHECKIN";
    public static SHORTCUT_KEY_ISSUE_BADGE = "SHORTCUT_KEY_ISSUE_BADGE";
    public static SHORTCUT_KEY_CHECKOUT = "SHORTCUT_KEY_CHECKOUT";

    public static WATCHLIST_SHOW_USER_POPUP = "WATCHLIST_SHOW_USER_POPUP";
    public static TPM_BUILD_VERSION ="buildVersion";

    public static TYPE_TEXTFIELD = "fabric_form_textfield";
    public static TYPE_TEXTAREA = "fabric_form_textarea";
    public static TYPE_DROPDOWN = "fabric_form_combo";
    public static TYPE_CHECKBOX = "fabric_form_checkbox";
    public static TYPE_SLIDETOGGLE = "fabric_form_slidetoggle";
    public static TYPE_RADIOBUTTON = "fabric_form_radiobutton";
    public static TYPE_MULTISELECT_USERSEARCH = "fabric_form_multiselect_usersearch";
    public static TYPE_DATE = "fabric_form_date";
    public static TYPE_AUTOCOMPLETE = "fabric_form_autocomplete";
    public static TYPE_USERSEARCH = "fabric_form_usersearch";
    public static AUTOCOMPLETE_VALUE_KEY = "additionalProp1";
    public static USERSEARCH_VALUE_KEY = "UserId";
    public static END_DATE_WITHOUT_AUTO_EXPAND = "END_DATE_WITHOUT_AUTO_EXPAND";
}