import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppParameterService {

  queryParameters = {};
  prevPageData = {};

  constructor() { }

  getQueryParameters(){
    return this.queryParameters;
  }

  setQueryParameters(queryParameters){
    this.clearQueryParameters();
    this.queryParameters = queryParameters;
  }

  clearQueryParameters(){
    this.queryParameters = {};
  }

  setPrevPageData(prevPageData){
    this.clearPrevPageData();
    this.prevPageData = prevPageData;
  }

  getPrevPageData(){
    return this.prevPageData;
  }

  clearPrevPageData(){
    this.prevPageData = {};
  }
}
