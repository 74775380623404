
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { TranslationLoaderService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-translate/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { FabricResourceBundleKeys } from 'alntg-fabric-ui-components/src/app/fabric/fabric-resource-bundle.keys';
import { Title } from '@angular/platform-browser';
import { Restangular } from 'ngx-restangular';
import { map, catchError } from 'rxjs/operators';
import { AppParameterService } from '../../services/app-paramter-service/app-parameter.service';
import { AppConstant } from './../../app.constant';
import {AppServiceService} from './../../services/app-service/app-service.service'



var self;

@Injectable()
export class AppAuthGuard implements CanLoad,CanActivate {
  

  //Reference
  //https://stackoverflow.com/questions/43857783/angular-2-how-do-i-get-route-parameters-from-canload-implementation
  constructor(public router: Router, 
    private translate: TranslateService,
    public appServiceService : AppServiceService,
    private translationLoader: TranslationLoaderService,
    private titleService: Title,
    public restangular: Restangular,
    public appParameterService: AppParameterService) { }



  canLoad(
    route: Route): Observable<boolean> | Promise<boolean> | boolean {
    //https://github.com/angular/angular/issues/12157
    //Can't read query params using Angular as it is set by an external server
    //Therefore, using javascript to read it

    let url = window.location.href;
    if (url.indexOf('access_token=') !== -1) {
      let queryParamsString = url.split('access_token=');
      if (queryParamsString.length > 1) {
        let access_token = queryParamsString[1].split('#');
        if (access_token[0]) {
          localStorage.setItem(AppConstant.TPM_TOKEN, access_token[0]);
          let newUrl = queryParamsString[0] + access_token[1];
          newUrl = newUrl.replace('?', '#');
          window.location.href = newUrl;
          return true;
        } else {
          this.router.navigate(['/pageNotFound']);
          return false;
        }

      }
    }

    return this.getQueryParameters(url, route.path)
  }

  landingParam(podId){
    return this.restangular.one('/rest/ui/landingPageParameter').customPOST({podId:podId},"",{},{}).pipe(map((result:any)=>{
      if(result instanceof Object){
        result['podId'] = podId;
      }
      this.appParameterService.setQueryParameters(result);
      this.loadFabricModuleResourceKeys();
      return true;
    },error=>{
      this.router.navigate(['/pageNotFound']);
      return false;
    }),catchError(this.handleError));
  }

  getQueryParameters(url, nextRoute){
    let queryParamString = decodeURIComponent(url.split("?")[1]);
    self = this;
    if(queryParamString && queryParamString.indexOf('randomVal') != -1){
      let encodedString = atob(queryParamString.split("randomVal=")[1]);
      let podIdString = encodedString.split("&")[0];
      let queryParam = podIdString.split("podId=");
      let podId = queryParam[1];
      return this.landingParam(podId);
    }else{
        if(localStorage.getItem(AppConstant.TPM_TOKEN)) {
          let newUrl = decodeURIComponent(url);
          if (newUrl.indexOf("visits/newvisits") != -1 && nextRoute.indexOf('visits') != -1) {
            this.appServiceService.showToolbarSubject.next(false);
            this.appServiceService.getGuardianUrlCall().subscribe(result => {
              if (result) {
                let guardianUrl = result[AppConstant.REST_SETUP_REDIRECT_URL];
                if (guardianUrl.indexOf('redirectPage.html') != -1) {
                  window.location.href = guardianUrl + '?access_token=' + localStorage.getItem(AppConstant.TPM_TOKEN) + "&uri=#!jalnt/identity";
                } else {
                  window.location.href = guardianUrl;
                }
              }
            })
          }
        } else {
          this.router.navigate(['/pageNotFound']);
          return false;
        }
      this.loadFabricModuleResourceKeys();
      return true;
    }
  }

  private handleError(error: any) {
    self.router.navigate(['/pageNotFound']); 
    return observableThrowError(false);
  }

  loadFabricModuleResourceKeys(){
    this.translationLoader.loadTranslations(this.getResourceKeys(), "rest/guardian/resourceBundle/values/FabricModule").subscribe(result => {
      this.translate.setTranslation('en', result, true)
      this.titleService.setTitle(result['atpm.header.title']);
    });
  }

  getResourceKeys(){
   return FabricResourceBundleKeys.loadResourceBundleKeys().concat(["common.button.cancel","common.ok","atpm.session.timeout.message","atpm.session.timeout.title","atpm.cache.refresh.title","atpm.cache.refresh.message","atpm.header.title",'atpm.menu.signout'])
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.loadFabricModuleResourceKeys();
    return true;
  }

}
