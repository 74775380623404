import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AppParameterService } from '../../services/app-paramter-service/app-parameter.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { FabricService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-service/fabric.service';

@Component({
  selector: 'ae-direct-link',
  templateUrl: './direct-link.component.html',
  styleUrls: ['./direct-link.component.css']
})
export class DirectLinkComponent implements OnInit {

  @Input() directLinks = [];
  @Output() closeDirectSidenav = new EventEmitter();

  nameToIconNameMap = {
    Dashboard : 'visitordashboard',
    ModifyVisits : 'managevisitor',
    KioskSetup : 'kiosksetup',
    Watchlist : 'watchlist',
    TPMScheduler : 'jobscheduler',
    ManageTempBadge : 'manageTempbadge'
  }

  constructor(public forRouting: Router,
    public appParameterService : AppParameterService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,protected fabricService:FabricService) {
      iconRegistry.addSvgIcon('jobscheduler',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/jobSchedule.svg'));    
      iconRegistry.addSvgIcon('kiosksetup',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/kioskSetup.svg'));    
      iconRegistry.addSvgIcon('managevisitor',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/manageVisitor.svg'));    
      iconRegistry.addSvgIcon('visitordashboard',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/visitorDashboard.svg'));    
      iconRegistry.addSvgIcon('watchlist',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/watchList.svg'));
      iconRegistry.addSvgIcon('manageTempbadge',sanitizer.bypassSecurityTrustResourceUrl(this.fabricService.getImageDefaultPath()+'assets/svgs/manageTempBadge.svg'));    
     }

  ngOnInit() {
  }

  linkClicked(link){
    let routingLink = link.redirectLink;
    if(routingLink){
      let routes = routingLink.split('?');
      this.appParameterService.clearQueryParameters();
      if(routes[1]){
        let queryParameters = {};
        let queryParamString = routes[1];
        let queryParamsWithValue = queryParamString.split("&");
        if(queryParamsWithValue && queryParamsWithValue.length > 0){
          for(let itr = 0; itr < queryParamsWithValue.length; itr++){
            if(queryParamsWithValue[itr].indexOf("=") != -1){
              let queryParamAndValue = queryParamsWithValue[itr].split("=");
              queryParameters[queryParamAndValue[0]] = queryParamAndValue.length > 1 && queryParamAndValue[1] ? queryParamAndValue[1] : true;
            }else{
              queryParameters[queryParamsWithValue[itr]] = true;
            }
          }
          this.appParameterService.setQueryParameters(queryParameters);
        }
      }
      this.forRouting.navigate([routes[0]]);
      this.closeDirectSidenav.emit(true);
    }
  }

  isActive(link){
    if(window.location && window.location.href && link.redirectLink){
      let href = window.location.href;
      if(href.indexOf(link.redirectLink.split('?')[0]) > -1){
        return true;
      }
    }
    return false;
  }

}
