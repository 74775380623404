
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserSessionService {

  private initValue = false;
  public behSubject = new BehaviorSubject<boolean>(this.initValue);

  constructor(private httpClient: HttpClient) { }


  emitSubject(){
    this.initValue = true;
    this.behSubject.next(this.initValue);
  } 

  private handleError(error: any) {
    let errMsg = (error.message) ? error.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }


}
