
import {throwError as observableThrowError, of as observableOf, Observable} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstant } from './../../../app.constant';

@Injectable()
export class VisitService {
  
  constructor(private restangular : Restangular, private httpClient: HttpClient) { }

  getVisitorRegFormlyFormData(params) : Observable <any> {
    return this.restangular.one('rest/visits/getLayoutByIdentityActionName').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getGroupVisitData(groupId) : Observable <any> {
    return this.restangular.one('rest/visits/getGroupVisitData/' + groupId).get().pipe(map((res:Response)=>res),catchError(this.handleError));
  }
  
  getVisitRegFormlyFormData(params) : Observable <any> {
    return this.restangular.one('rest/guardian/getLayoutForLocation').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  validateVisits(visitsList) : Observable <any>{
    return this.restangular.all('rest/visits/validateVisits').customPOST(visitsList,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getVisitorDuplicateSearchAttr() : Observable <any>{
    return this.restangular.one('rest/guardian/getVisitorDuplicateSearchAttr').get().pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getTemplateGridProperties(identityAction) : Observable <any> {
    return this.restangular.one('rest/visits/getTemplateGridProperties').customGET(identityAction,{}).pipe(map((res)=>res),catchError(this.handleError));    
  }

  createGroupVisits(data) : Observable <any>{
    return this.restangular.one('rest/visits/createGroupVisit').customPOST(data,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  createVisitsCutOver(data) : Observable <any>{
    return this.restangular.one('rest/visits/createVisitsCutOver').customPOST(data,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getExtraFieldsByDataCenter() : Observable <any> {
    return observableOf();
  } 

  saveVisitorRegData(data) : Observable <any>{
    return this.restangular.one('rest/visits/').customPOST(data,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleRegError));
  }

  saveUserSignature(data) : Observable <any>{
    return this.restangular.one('rest/visits/saveUserSignature').customPOST(data).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  deleteAttachmentOnDestroy(listToDelete) : Observable <any>{
    return this.restangular.one('rest/visits/deleteAttachOnDestroy').customPOST(listToDelete,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getGuardianUrl() : Observable<any>{
    return this.restangular.one('rest/guardian/guardianUrl').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }
  
  getPolicyLayout(data) : Observable<any>{
    return this.restangular.one('rest/visits/getLayoutByIdentityActionName').get(data).pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  getPolicyBasedOnLocation(params) : Observable <any> {
    return this.restangular.one('rest/guardian/getLayoutForLocation').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  downloadCsvTemplate(identityAction,locationName,layoutType) : Observable <any>{

      var access_token = localStorage.getItem(AppConstant.TPM_TOKEN) ? localStorage.getItem(AppConstant.TPM_TOKEN) : '';

      const httpOptions = {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + access_token)
      };
      

      return this.httpClient.get('rest/visits/downloadCsvTemplate/' + identityAction + "/" + locationName + "/"  + layoutType, {responseType: 'blob', headers: httpOptions.headers}).pipe(map((res)=>{
        console.log(res);
        return res;
      }),catchError(this.handleError),);

  }
  
   getHierarchyLocations(params) : Observable <any>{        
      return this.restangular.one('rest/visits/locationsHierarchy').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getLocationsForCutOver(params) : Observable <any>{           
    return this.restangular.one('rest/guardian/locationsForCutOver').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));  
  }
  
  getValidateVisitBatchSize() :Observable <any>{
    return this.restangular.one('rest/guardian/getValidateVisitBatchSize').get().pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getPUParamValueForGroupHeader() : Observable<any>{
    return this.restangular.one('rest/guardian/getPUParamValueForGroupHeader').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  getExcludedEmailDomain() : Observable<any>{
    return this.restangular.one('rest/guardian/getExcludedEmailDomain').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  getSetupParameterByName(parameterName){
    return this.restangular.one('rest/guardian/setupParameterByName').get({"parameterName":parameterName}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getAttributesInValidationRuleNotInForm(paramName){
    return this.restangular.one('rest/guardian/appConfigParam').get({"paramName":paramName}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  checkReturnVisitor(visitModel){
    return this.restangular.one('rest/visits/checkReturnVisitor').post("",visitModel).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  populateVisitDetails(userId,identityAction){
    return this.restangular.one('rest/visits/populateVisitDetails').get({userId:userId,identityAction:identityAction}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  getResourceValue(resourceKey){
    return this.restangular.one('rest/guardian/resourceBundle/values/VisitsModule').customPOST([resourceKey],'',{},{}).pipe(map((res:Response) => res),catchError(this.handleError));
  }

  getApplicationConfigParam(paramName){
    return this.restangular.one('rest/guardian/appConfigParam').get({"paramName":paramName}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  saveCapturedUserImage(imageDetails) : Observable <any> {
    return this.restangular.one('rest/visits/saveCapturedUserImage').customPOST(imageDetails,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError)); 
  }

  getAttachment(watchlistId,isThumbnail){
    return this.restangular.one('rest/watchlist/getPhotoByWatchlistId/'+watchlistId).get({isThumbnail : isThumbnail}).pipe(map((res:Response)=>res),catchError(this.handleError));

  }

  private handleError(error: any) {
    let errMsg = (error.data.message) ? error.data.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }

  private handleRegError(error: any) {
    if(error.data.errorCode && error.data.errorCode == 1885){
      return observableThrowError(error.data);
    }else{
      let errMsg = (error.data.message) ? error.data.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      return observableThrowError(errMsg);
    }
  }

}

