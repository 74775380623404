import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentityCorrectionComponent } from './components/identity-correction/identity-correction.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCheckboxModule, MatSidenavModule, MatInputModule, MatSelectModule, MatDatepickerModule, MatRadioModule, MatNativeDateModule, MatTooltipModule, MatTabsModule } from '@angular/material';
import { FabricModule } from 'alntg-fabric-ui-components/src/app/fabric/fabric.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PolicyCheckComponent, SafePipe } from './components/policy-check/policy-check.component';
import { TakeSignatureComponent } from './components/take-signature/take-signature.component';
import { TranslationLoaderService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-translate/translation-loader.service';
import { SharedResourceBundleKeys } from './shared-resource-bundle.key';
import { FaceRecognitionComponent } from './components/face-recognition/face-recognition.component';
import { FaceRecognitionService } from './components/face-recognition/service/face-recognition.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule, 
    MatRadioModule,
    MatNativeDateModule,
    FabricModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTabsModule,
  ],
  declarations: [IdentityCorrectionComponent,PolicyCheckComponent,SafePipe, TakeSignatureComponent, FaceRecognitionComponent],
  entryComponents : [IdentityCorrectionComponent,PolicyCheckComponent],
  exports : [IdentityCorrectionComponent,PolicyCheckComponent,SafePipe,TakeSignatureComponent, FaceRecognitionComponent],
  schemas:[ NO_ERRORS_SCHEMA ],
  providers: [FaceRecognitionService]
})
export class SharedModule {
  constructor( private translate: TranslateService,
    private translationLoader: TranslationLoaderService){
      this.translationLoader.loadTranslations(SharedResourceBundleKeys(),"rest/guardian/resourceBundle/values/VisitsModule").subscribe(result=>{
        this.translate.setTranslation('en', result, true)
      });
    }
 }
