import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable, throwError as observableThrowError } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
@Injectable()
export class FaceRecognitionService {
    constructor(private restangular: Restangular){}

    walkinFaceRecognize(data: any, queryParams: any) : Observable <any>{
        return this.restangular.one('rest/visits/walkinFaceRecognize').customPOST(data, undefined, queryParams, {'Content-Type': undefined}).pipe(map((res:Response)=>res),catchError(this.handleError));
    }

    faceRecognize(data: any, queryParams: any) : Observable <any>{
        return this.restangular.one('rest/visits/faceRecognize').customPOST(data, undefined, queryParams, {'Content-Type': undefined}).pipe(map((res:Response)=>res),catchError(this.handleError));
    }
  
    private handleError(error: any) {
      let errMsg = (error.data.message) ? error.data.message :
          error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      return observableThrowError(errMsg);
    }   
}