
import {throwError as observableThrowError, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import { map,catchError } from 'rxjs/operators';

@Injectable()
export class IdentityCorrectionService {

  constructor(private restangular : Restangular) { }
  

  loadData(id){
    return this.restangular.one('rest/visits/visitorDetailsForCorrection/',id).get().pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  loadDataPU(params){
    return this.restangular.one('rest/visits/getLayoutByIdentityActionName').get(params).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  saveIdentityCorrection(data,userId) : Observable <any>{
    return this.restangular.one('rest/visits/visitorDetailsForCorrection?userId='+userId).customPOST(data,"",{},{}).pipe(map((res:Response)=>res),catchError(this.handleError));
  }

  private handleError(error: any) {
    let errMsg = (error.data.message) ? error.data.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }
}
