import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {throwError as observableThrowError, of as observableOf, Observable} from 'rxjs';
import {Restangular} from 'ngx-restangular';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor(private restangular : Restangular) { }

  showToolbar: boolean = true;
  policyData : Array<any> = []
  showToolbarSubject = new Subject();
  guardianUrl : string;
  sessionTimeoutNotifierSubject = new Subject();

  getPolicyData(){
    return this.policyData;
  }

  setPolicyData(policyData){
    this.policyData = policyData;
  }

  setGuardianUrl(guardianUrl){
    this.guardianUrl = guardianUrl;
  }

  getGuardianUrl(){
    return this.guardianUrl;
  }
  getGuardianUrlCall() : Observable<any>{
    return this.restangular.one('rest/guardian/guardianUrl').get().pipe(map((res:Response)=>res),catchError(this.handleError));    
  }

  private handleError(error: any) {
    let errMsg = (error.data.message) ? error.data.message :
        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return observableThrowError(errMsg);
  }
}
