import { timer as observableTimer } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatButtonModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule, MatSidenavModule, MatInputModule, MatSelectModule } from "@angular/material";
import { MatDatepickerModule, MatNativeDateModule } from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes, Router } from "@angular/router"; 
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestangularModule } from 'ngx-restangular';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule,TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-translate/translation-loader.service';
import { FabricModule } from "alntg-fabric-ui-components/src/app/fabric/fabric.module";
import { AppComponent } from './app.component';
import { LocationStrategy,HashLocationStrategy } from '@angular/common';
import { Utils } from './utils';
import { AppAuthGuard } from './guards/app-guard/app-auth.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MatSnackBar } from '@angular/material';
import { FabricSnackbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-snackbar/fabric-snackbar.component';
import { AppSettingsService } from './services/app-settings.service';
import { UserSessionService } from './services/user-session/user-session.service';
import { MatDialog } from '@angular/material';
import { FabricDialogComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-dialog/fabric-dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { WebcamModule } from 'ngx-webcam';
import { VisitService } from './visits/visits/services/visit.service';
import { AppConstant } from './app.constant';
import { FabricService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-service/fabric.service';
import { TakeSignatureComponent } from './shared/components/take-signature/take-signature.component';
import { SharedModule } from './shared/shared.module';
import { AppServiceService } from './services/app-service/app-service.service';
import { DirectLinkComponent } from './components/direct-link/direct-link.component';
import { ViewAccessGuard } from './guards/view-access-guard/view-access.guard';
import { ViewPermissionsService } from './guards/view-access-guard/service/view-permissions.service';
import { HotkeyModule } from 'angular2-hotkeys';

const appRoutes: Routes = [
  { path:'home',loadChildren:() => import('./home-view/home-view.module').then(m => m.HomeViewModule),
  canLoad: [AppAuthGuard],
   resolve : {
     loadingAppSettings : AppSettingsService
   } 
  },
  { path:'visits',loadChildren:() => import('./visits/visits.module').then(m => m.VisitsModule),
  canLoad: [AppAuthGuard],
  resolve : {
    loadingAppSettings : AppSettingsService
  } 
  },
  { path:'scheduler',loadChildren:() => import('./scheduler/scheduler.module').then(m => m.SchedulerModule),
    canLoad: [AppAuthGuard],
    resolve : {
      loadingAppSettings : AppSettingsService
    }
  },
 { path:'guardian',loadChildren:() => import('./guardian-rest-setup/guardian-rest-setup.module').then(m => m.GuardianRestSetupModule),
   canLoad: [AppAuthGuard],
   resolve : {
      loadingAppSettings : AppSettingsService
   }
  },
  { path:'kiosk',loadChildren:() => import('./kiosk/kiosk.module').then(m => m.KioskModule),
    canLoad: [AppAuthGuard],
    resolve : {
       loadingAppSettings : AppSettingsService
    }
  },
  { path:'watchlist',loadChildren:() => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
    canLoad: [AppAuthGuard],
    resolve : {
       loadingAppSettings : AppSettingsService
    }
  },
  { path:'manageTempBadge',loadChildren:() => import('./manage-temp-badge/manage-temp-badge.module').then(m => m.ManageTempBadgeModule),
    canLoad: [AppAuthGuard],
    resolve : {
       loadingAppSettings : AppSettingsService
    }
  },
  { path:'login',loadChildren:() => import('./login/login.module').then(m => m.LoginModule)},
  { path:'takeSignatures',component: TakeSignatureComponent ,canActivate: [ViewAccessGuard],data : {screenName : "Dashboard,Visits"}},
  { path: 'pageNotFound', component: PageNotFoundComponent},
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: '**', redirectTo: 'pageNotFound' },
  
];
var sessionTimeOut = 1680000;  //By deafult 28 mins


// Function for setting the default restangular configuration
export function RestangularConfigFactory (RestangularProvider, router: Router, matSnackbar: MatSnackBar, dialog: MatDialog, userSessionService: UserSessionService, appService : AppServiceService) {

  var timer;
  RestangularProvider.setBaseUrl(Utils.getDocumentsRoot());
  RestangularProvider.setPlainByDefault(true);
  RestangularProvider.addResponseInterceptor((data, operation, what, url, response, deferred)=> {
    if(response.headers.get('access_token')){
      localStorage.setItem(AppConstant.TPM_TOKEN,response.headers.get('access_token'));
    }
    return data;
  });
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params)=> {
	return {
		headers: Object.assign({}, headers, {'Authorization': 'Bearer ' + localStorage.getItem(AppConstant.TPM_TOKEN)})
	}
});
RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
  //Show error msgs on UI code to be implemented here
  //Navigate to page not found if user is unauthorized for any call
  if(response.status === 403){
    router.navigate(['/pageNotFound']);
    //fabricMessagesService.showMessage('fail', 'Authorization Failed!! You are not authorized to view this page.');
    //can take horizontalPosition: center or start or end, verticalPosition: bottom or top
    matSnackbar.openFromComponent(FabricSnackbarComponent, { horizontalPosition: "center", verticalPosition: "bottom", panelClass: ['ae-snackbar'], data: "Authorization Failed!! You are not authorized to view this page.", duration: 4000});
  }
  return true;
});

RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params)=> {
  /*return {
    params: Object.assign({}, params, {sort:"name"}),
    headers: headers,
    element: element
  }*/
  clearTimeout(timer);
  timer = setTimeout(() => {
    appService.sessionTimeoutNotifierSubject.next({
      isSessionTimeoutPopupVisible: true
    });
    let timeObsevable = observableTimer(150000);
    let timerSub = timeObsevable.subscribe(()=>{
      clearTimeout(timer);
      userSessionService.emitSubject();
      timerSub.unsubscribe();
      
    });

    let signoutTimerObservable = observableTimer(120000);
    let signoutTimerSub = signoutTimerObservable.subscribe(()=>{
      localStorage.removeItem(AppConstant.TPM_TOKEN);
      let context = appService.getGuardianUrl().split("AlertEnterprise")[0] + "AlertEnterprise";
      window.location.href = context;
    });

    var dialogRef = dialog.open(FabricDialogComponent, {
            data: { title:"atpm.session.timeout.title" , 
                    actionButtons: [{
                      title: "common.ok",
                      clickHandler: (dialogref)=>{
                        appService.sessionTimeoutNotifierSubject.next({
                          isSessionTimeoutPopupVisible: false
                        });
                        timerSub.unsubscribe();
                        clearTimeout(timer);
                        signoutTimerSub.unsubscribe();
                        userSessionService.emitSubject();
                        dialogref.close('OK');
                      }
                    },{
                      title: "common.button.cancel",
                      clickHandler: (dialogref)=>{
                        localStorage.removeItem(AppConstant.TPM_TOKEN);
                        let context = appService.getGuardianUrl().split("AlertEnterprise")[0] + "AlertEnterprise";
                        window.location.href = context;
                        signoutTimerSub.unsubscribe();
                        dialogref.close('cancel');
                      }
                    }],
                  content: "atpm.session.timeout.message" }
          });
      
          dialogRef.afterClosed().subscribe(result => {
          });
          dialogRef.disableClose = true;
  },sessionTimeOut); //show dialog after 28 minutes
});
 
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ChangePasswordComponent,
    DirectLinkComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    TranslateModule.forRoot(),
    FabricModule.forRoot(),
    HotkeyModule.forRoot(),
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    RestangularModule.forRoot([Router, MatSnackBar, MatDialog, UserSessionService,AppServiceService], RestangularConfigFactory),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxWebstorageModule,
    WebcamModule,
    SharedModule
  ],
  providers: [TranslationLoaderService,AppSettingsService, UserSessionService,
              { provide: LocationStrategy, useClass: HashLocationStrategy }, 
              AppAuthGuard,VisitService,ViewAccessGuard,ViewPermissionsService],
  entryComponents : [ChangePasswordComponent],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ] // add this line for supporting HTML tags inside ng-content
})
export class AppModule {
  constructor( private translate: TranslateService,private appSettingsService:AppSettingsService,private translationLoader: TranslationLoaderService,private userSessionService: UserSessionService,private fabricService: FabricService){
      
      this.fabricService.setTokenName(AppConstant.TPM_TOKEN);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      appSettingsService.appDataCatcher.subscribe((data)=>{
        sessionTimeOut = data["sessionTimeOut"]; 
        sessionTimeOut = (sessionTimeOut - 120)*1000; //subtracting 2 mins and converting seconds to miliseconds
        userSessionService.emitSubject();
      });
    }
 }
