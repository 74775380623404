import { Component, OnInit, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Restangular } from 'ngx-restangular';
import {FabricDialogComponent} from 'alntg-fabric-ui-components/src/app/fabric/fabric-dialog/fabric-dialog.component'
import { MatDialog, MatSnackBar, MatSidenav, MatIconRegistry } from '@angular/material';
import {AppSettingsService} from './services/app-settings.service';
import { UserSessionService } from './services/user-session/user-session.service';
import { Route, Router } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {WebcamImage} from 'ngx-webcam';
import { AppConstant } from './app.constant';
import { FabricSnackbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-snackbar/fabric-snackbar.component';
import { AppServiceService } from './services/app-service/app-service.service';
import { FabricToolbarComponent } from 'alntg-fabric-ui-components/src/app/fabric/fabric-toolbar/fabric-toolbar.component';
import { FabricService } from 'alntg-fabric-ui-components/src/app/fabric/fabric-service/fabric.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation : ViewEncapsulation.None
})
export class AppComponent implements OnInit{

  messageType: string;
  messages: any;
  showToolbar:boolean=true;
  constructor(public appSettingsService : AppSettingsService, private restangular : Restangular,public dialog: MatDialog, public userSessionService: UserSessionService, private router: Router, private cd: ChangeDetectorRef,private matSnackbar: MatSnackBar, private forRouting: Router,public appService: AppServiceService){}

  themeClass = 'hhh';
  loggedInUser : string = '';
  appData = {};
  menuContent = [];
  guardianUrl;
  public logo: WebcamImage = null;
  pageCodes;
  showDefaultImage = false;
  directLinksData = [];

  @ViewChild('sidenav', {static : true}) sidenav:MatSidenav;
  @ViewChild('toolbarRef', {static : false}) toolbarRef : FabricToolbarComponent;

  applyTheme(themeName){
    this.themeClass = themeName == 'dark' ? 'dark-theme' : '';
  }

  ngOnInit() {
    this.appSettingsService.appFavicon();
    this.setInitData();
  }

  setInitData(){
    this.getDirectLinkData();
    this.appService.showToolbarSubject.subscribe(result => {
      if (result == false) {
        this.showToolbar = false;
      }
      else {
        this.showToolbar = true;
      }
    })

    this.appSettingsService.appDataCatcher.subscribe(result=>{
      this.appData = result;
      if(this.appData['logo']){
        var mimeTypeSubset = this.appData['logo'].split(';');
        var mimeType = mimeTypeSubset[0].split(':');    
        this.logo = new WebcamImage(this.appData['logo'], mimeType[1],null);
      }else{
        this.showDefaultImage = true;
      }
     
      if(this.appData['fullName']){
        this.loggedInUser = this.appData['fullName'];
        this.guardianUrl = this.appData['guardianUrl'];
        
        if(this.router.url && this.router.url.indexOf('/guardian/restsetup') != -1){
          this.setMenuContextFull();
        }
        else{
          this.setMenuContextPartial(); //Dont show logout menu item
        }
      }
      if(this.appData['pageCodes']){
        this.pageCodes = this.appData['pageCodes'];
      }
      if(this.appData['buildVersion']){
        if(localStorage.getItem(AppConstant.TPM_BUILD_VERSION)){
          if(localStorage.getItem(AppConstant.TPM_BUILD_VERSION) != this.appData['buildVersion'] ){
            
            var dialogRef = this.dialog.open(FabricDialogComponent, {
              data: { title:"atpm.cache.refresh.title" , 
                      actionButtons: [{
                        title: "common.ok",
                        clickHandler: (dialogref)=>{
                          dialogref.close();
                          localStorage.setItem(AppConstant.TPM_BUILD_VERSION,this.appData['buildVersion']);
                          window.location.reload(); 
                        }
                      },{
                        title: "Close",
                        clickHandler: (dialogref)=>{
                          dialogref.close('cancel');
                        }
                      }],
                    content: "atpm.cache.refresh.message" }
            });
        
            dialogRef.afterClosed().subscribe(result => {
            });
            dialogRef.disableClose = true;
          }
        }else{
          localStorage.setItem(AppConstant.TPM_BUILD_VERSION,this.appData['buildVersion']);
        }
      }
    
    })

    this.userSessionService.behSubject.subscribe((val)=>{
      if(val === true){
      this.appSettingsService.resetUserSession();
    }
  });

    this.appSettingsService.userLoggedInTrigger.subscribe(result => {
      if(result){
        this.setMenuContextFull();
        console.log(result);
      }
      else{
        this.loggedInUser = '';
      }
    });
  }

  getDirectLinkData(){
    this.appSettingsService.getDirectLinks().subscribe(result =>{
      this.directLinksData = result;
    });
  }

  setMenuContextPartial(){
    this.menuContent = [
      {
        menuItem:'About',
        btnClckHandler:()=>{
          let dialogRef = this.dialog.open(FabricDialogComponent, {
            data: { 
                    title: 'About', 
                    actionButtons: [{
                      title: 'Close',
                      clickHandler: (dialogref)=>{
                        dialogref.close();
                      }
                    }],
                  content: "Build Version : " + this.appData['buildVersion'] 
                }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      },
      {
        menuItem:'atpm.menu.signout',
        btnClckHandler:()=>{
          localStorage.removeItem(AppConstant.TPM_TOKEN);
          let context = this.guardianUrl.split("AlertEnterprise")[0] + "AlertEnterprise/logout";
          window.location.href = context;
        }
      }
    ]
  }


  setMenuContextFull(){
    this.menuContent = [
      {
        menuItem:'About',
        btnClckHandler:()=>{
          let dialogRef = this.dialog.open(FabricDialogComponent, {
            data: { 
                    title: 'About', 
                    actionButtons: [{
                      title: 'Close',
                      clickHandler: (dialogref)=>{
                        dialogref.close();
                      }
                    }],
                  content: "Build Version : " + this.appData['buildVersion'] 
                }
          });
          dialogRef.afterClosed().subscribe(result => {
          });
        }
      },
      {
        menuItem:'Sign Out',
        btnClckHandler:()=>{
          localStorage.removeItem(AppConstant.TPM_TOKEN);
          this.appSettingsService.userLoggedInOrOut(false);
          this.router.navigate(['/login']);
        }
      }
    ]
  }

  hamburgerClicked(event){
    this.sidenav.toggle();
  }

  openedStart(event){
    this.toolbarRef.isSidenavOpened = true;
    let elem = document.getElementsByClassName('mat-drawer-backdrop');
    if(elem && elem[0]){
      let element = elem[0];
      element.classList.add('ae-tpm-directlink-backdrop');
    }
  }

  closedStart(event){
    this.toolbarRef.isSidenavOpened = false;
    let elem = document.getElementsByClassName('mat-drawer-backdrop');
    if(elem && elem[0]){
      let element = elem[0];
      element.classList.remove('ae-tpm-directlink-backdrop');
    }
  }

}
